import React, {useEffect} from 'react';

function ResApiDoc(props) {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://cdn.redoc.ly/redoc/latest/bundles/redoc.standalone.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);
    return (
        <div><redoc  spec-url='https://propisi.info/openapi.yaml'></redoc></div>
    );
}

export default ResApiDoc;