import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react-lite";
import ToolBar from "../ToolBar";
import {useHistory, useParams} from "react-router-dom";
import {Card} from "primereact/card";
import {usePrescriptionsStore} from "../../../../store/states/PrescriptionsStore";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import PrescriptionDialog from "./PrescriptionDialog";
import {BlockUI} from "../../../Common/BlockUI";
import {format, parseISO} from "date-fns";
import {getSnapshot} from "mobx-state-tree";
import {Paginator} from "primereact/paginator";
import {useMatchMedia} from "../../../../hooks";

function PatientCard(props) {
    let { id } = useParams();
    const store = props.store;
    const {isMobile} = useMatchMedia();
    const history = useHistory();
    const prescriptionStore = usePrescriptionsStore();
    const [showPrescriptionDialog, setShowPrescriptionDialog] = useState(false)

    const [prescriptionsParams, setPrescriptionsParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })

    useEffect(()=>{
        prescriptionStore.fetch({...prescriptionsParams, patient_id:id, doctor_id:store.doctorId})
        store.fetchPatient({patient_id:id, doctor_id:store.doctorId})
    },[id, store, prescriptionStore, prescriptionsParams])

    const dateBody = (rowData) =>{
        return format(parseISO(rowData.date) ,"dd.MM.yyyy")
    }

    const onPageChange = (e)=>{
        setPrescriptionsParams({...prescriptionsParams, first:e.first, rows:e.rows, page: e.page })
    }

    const patientHeader = () =>(
        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", paddingTop:"5px", paddingLeft:"10px"}}>
            <div style={{display:"flex", alignItems:"center"}}>
                <span  style={{paddingLeft:"4px", fontSize:"1.2rem", fontWeight:"bold"}}>
                    Пациент: {store.patient.fullName}
                </span>
            </div>
            <div style={{paddingRight:"10px"}}>
                <Button rounded text icon={"pi pi-times"} onClick={()=>{history.push("/doctor")}}/>
            </div>
        </div>
    )

    if (!store.patient) return (<></>)
    return (
        <div>
            <ToolBar />
            {showPrescriptionDialog &&<PrescriptionDialog
                visible={showPrescriptionDialog} onHide={()=>setShowPrescriptionDialog(false)}
                onSave={(data)=>{prescriptionStore.updatePrescription(data)}}
            />}
            <BlockUI blocking={prescriptionStore.isLoading}>
            <Card header={patientHeader}  className="m-2">
                <DataTable
                    header={"Назначения"}
                    value={prescriptionStore.items}
                    selection={prescriptionStore.selected}
                    selectionMode="single"
                    onSelectionChange={(e)=>{
                        if (e.value){
                            prescriptionStore.setSelected(e.value)
                            prescriptionStore.initEditable(getSnapshot(e.value))
                            setShowPrescriptionDialog(true)
                        }
                    }}
                    emptyMessage={"Назначений еще нет"}
                    onClick={(e)=>{
                        prescriptionStore.initEditable(getSnapshot(prescriptionStore.selected))
                        setShowPrescriptionDialog(true)
                    }}
                >
                    <Column body={dateBody} header={"Дата "}/>
                    <Column field={"id"} header={"Номер"}/>
                    <Column field={"content"} header={"Состав"}/>
                </DataTable>
                <Paginator
                    rows={prescriptionsParams.rows}
                    first={prescriptionsParams.first}
                    totalRecords={prescriptionStore.total}
                    onPageChange={onPageChange}
                    leftContent={()=>(
                        <Button icon={isMobile?"pi pi-plus":""} label={isMobile?"" : "Выписать назначение"}  rounded={isMobile} onClick={()=>{
                            prescriptionStore.initEditable({items:[], patient:getSnapshot(store.patient), date:new Date().toISOString()})
                            setShowPrescriptionDialog(true)}
                        } />
                    )}
                />
            </Card>
            </BlockUI>
        </div>
    )
}

export default observer(PatientCard)
