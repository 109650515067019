import React, {useContext, useRef, useState} from 'react'
import {PasswordCard, Row} from "./Common/Styles";
import {AppContext} from "./AppContext";
import {useHistory} from "react-router-dom";
import logo from "../images/logo4.png";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {BlockUI} from "./Common/BlockUI";

function RestorePass() {
    const context = useContext(AppContext);
    const history = useHistory();
    const [userNameOrEmail, setUserNameOrEmail] = useState("")
    const toast = useRef()
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = (e) =>{
        e.preventDefault();
        setIsLoading(true)
        context.apiService.restorePassword(userNameOrEmail).then(
            (data)=>{
                setIsLoading(false)
                if (data) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Внимание',
                        detail: "Ссылка для сброса пароля будет направлена на ваш почтовый адрес, указанный при регистрации в системе",
                        life: 3000
                    });
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Внимание',
                        detail: "Пользователь с таким именем или e-mail не найден в системе",
                        life: 3000
                    });
                }

            }
        ).catch((e)=>{
            setIsLoading(false)
            toast.current.show({
                severity: 'error',
                summary: 'Внимание',
                detail: e,
                life: 3000
            });
        })
    }

    return (
        <PasswordCard style={{width:"260px", height:"500px", margin:"auto"}}>
            <BlockUI blocking={isLoading}>
            <form onSubmit={onSubmit}>
                <Toast ref={toast} position="top-center"/>
                <Row>
                    <img src={logo}  alt={"Logo"} style={{width:"100%"}}/>
                </Row>
                <Row className="mt-2">
                   <p className={"text-base"}>
                       Для восстановления пароля укажите имя пользователя
                       или электронную почту, указанную при регистрации
                   </p>
                </Row>
                <Row className="mt-2">
                    <InputText
                        style={{width:"100%"}}
                        placeholder="Имя пользователя или e-mail"
                        value={userNameOrEmail}
                        onChange={(e)=>{
                            setUserNameOrEmail(e.target.value)
                        }}
                    />
                </Row>
                <Row className="mt-2">
                    <Button type="submit" style={{width:"100%"}} severity={"success"} label="Воостановить пароль"/>
                </Row>
                <Row className="mt-2">
                    <Button type="submit" style={{width:"100%"}}
                        label="Войти в систему"
                        onClick={()=>{history.push("/login")}}
                    />
                </Row>
            </form>
            </BlockUI>
        </PasswordCard>
    )
}

export default RestorePass
