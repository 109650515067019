import React, {useContext, useRef, useState} from 'react';
import {Toast} from "primereact/toast";
import {
    BorderedListItemEditor,
    PasswordCard,
    Row,
    SPassword
} from "./Common/Styles";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useHistory} from "react-router-dom";
import {AppContext} from "./AppContext";
import {Controller,useForm} from "react-hook-form";
import {InputTextarea} from "primereact/inputtextarea";
import ItemsList from "./Common/ItemsList";
import {classNames} from "primereact/utils";
import manual from "../images/01_РИ _Врача_.pdf";
import {Checkbox} from "primereact/checkbox";
import {InputMask} from "primereact/inputmask";

function Register(props) {

    const history = useHistory()
    const toast = useRef(null)
    const context = useContext(AppContext)
    const medOrgRef = useRef(null)
    const firstPasswordField = useRef(null)
    const [togglePasswordMask, setTogglePasswordMask] = useState(true)
    const [consent, setConsent] = useState(false)

    //const {control, handleSubmit, reset, watch, formState:{errors}, setValue, getValues} = useForm()
    const {control, handleSubmit, setValue} = useForm()

    const [displaySpecialityList, setDisplaySpecialityList] = useState(false)


    const showError = (data) => {
        toast.current.show({severity:'error', summary: 'Внимание', detail:`${data}`, life: 3000});
    }

    const showSuccess = (data) => {
        toast.current.show({severity:'success', summary: 'Внимание', detail:`${data}`, life: 3000});
    }

    const onRegister = (e) =>{
        context.apiService.sign_up(e).then((data)=>{
            showSuccess(data)
        }).catch( data =>{
            showError(data.response.data)
        })
    }

    return (
        <div>
            <div style={{display:"flex", height:"100%", minHeight:"70vh"}}>
                <Toast ref={toast} position="top-center" />

                <ItemsList
                    header="Специальности"
                    visible={displaySpecialityList}
                    onHide={() => setDisplaySpecialityList(false)}
                    getData={context.apiService.getSpeciality.bind(context.apiService)}
                    onAccept={(e) => {
                        setValue("speciality", e, {shouldDirty: true})
                        setDisplaySpecialityList(false)
                        if (medOrgRef.current) medOrgRef.current.focus()
                    }}
                />

                <PasswordCard style={{width:"400px", margin:"auto"}}>
                    <form onSubmit={handleSubmit(onRegister)}>
                        <Row className="mt-2">
                            <Controller
                                control={control}
                                name="surname"
                                rules={{required: 'Поле обязателено к заполнению.'}}
                                render={({field, fieldState})=>(
                                    <InputText
                                        {...field}
                                        style={{width:"100%"}}
                                        placeholder="Фамилия"
                                        className={classNames({'p-invalid': fieldState.invalid})}
                                    />
                                )}
                            />
                        </Row>

                        <Row className="mt-2">
                            <Controller
                                control={control}
                                name="name"
                                rules={{required: 'Поле обязателено к заполнению.'}}
                                render={({field, fieldState})=>(
                                    <InputText
                                        {...field}
                                        style={{width:"100%"}}
                                        placeholder="Имя"
                                        className={classNames({'p-invalid': fieldState.invalid})}
                                    />
                                )}
                            />
                        </Row>

                        <Row className="mt-2">
                            <Controller
                                control={control}
                                name="patronymic"
                                render={({field})=>(
                                    <InputText
                                        {...field}
                                        style={{width:"100%"}}
                                        placeholder="Отчество"
                                    />
                                )}
                            />
                        </Row>

                        <Row className="mt-2">
                            <Controller
                                control={control}
                                name="cert"
                                rules={{required: 'Поле обязателено к заполнению.'}}
                                render={({field, fieldState})=>(
                                    <InputText
                                        {...field}
                                        style={{width:"100%"}}
                                        placeholder="Номер сертификата"
                                        className={classNames({'p-invalid': fieldState.invalid})}
                                    />
                                )}
                            />
                        </Row>

                        <Row className= "mt-2">
                            <Controller
                                name="speciality"
                                control={control}
                                rules={{required: 'Поле обязателено к заполнению.'}}
                                render={({field, fieldState}) => (
                                    <BorderedListItemEditor
                                        value={field.value?.name || ""}
                                        style={{width:"100%"}}
                                        placeholder = "Специальность"
                                        onSelect={() => {
                                            setDisplaySpecialityList(true)
                                        }}
                                        onClear={() => {
                                            setValue("speciality", undefined, {shouldDirty: true})
                                        }}
                                        className={classNames({'p-invalid': fieldState.invalid})}
                                    />
                                )}
                            />
                        </Row>

                        <Row className="mt-2">

                            <Controller
                                control={control}
                                name="comment"
                                rules={{required: 'Поле обязателено к заполнению.'}}
                                render={({field, fieldState})=>(
                                    <InputTextarea
                                        forwardRef={medOrgRef}
                                        {...field}
                                        style={{width:"100%"}}
                                        placeholder="Медучреждение"
                                        className={classNames({'p-invalid': fieldState.invalid})}
                                    />
                                )}
                            />
                        </Row>

                        <Row className="mt-2">
                            <Controller
                                control={control}
                                name="email"
                                rules={{
                                    required: 'Email обязателен к заполнению.',
                                    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Неверный email адрес. E.g. example@email.com' }
                                }}
                                render = {({field, fieldState})=>(
                                    <InputText
                                        {...field}
                                        style={{width:"100%"}}
                                        placeholder="email"
                                        className={classNames({'p-invalid': fieldState.invalid})}
                                    />
                                )}
                            />
                        </Row>

                        <Row className="mt-2">
                            <Controller
                                name="phone"
                                control={control}
                                rules={{ required: 'Поле обязательно для заполнения.'}}
                                render={({field,fieldState}) =>

                                    <InputMask placeholder={"Телефон"} style={{width:"100%"}}
                                        className={classNames({ 'p-invalid': fieldState.invalid }) }
                                        {...field} mask="+7(999) 999-99-99" autoComplete="off"
                                    />
                                }
                            />
                        </Row>

                        <Row className="mt-2">
                            <Controller
                                control={control}
                                name="password"
                                render={({field})=>(
                                    <div style={{display:"flex", width:"100%"}}>
                                    <SPassword
                                        {...field}
                                        ref = {firstPasswordField}
                                        style={{width:"100%"}}
                                        placeholder="Пароль"
                                    />
                                    <div className={"pl-1"}/>
                                    <Button
                                        rounded text raised type={"button"}
                                        icon={togglePasswordMask?"pi pi-eye":"pi pi-eye-slash"}
                                        onClick={()=>{
                                                if (firstPasswordField.current){
                                                    firstPasswordField.current?.toggleMask()
                                                    setTogglePasswordMask(!togglePasswordMask)
                                                }
                                            }
                                        }
                                    />
                                    </div>
                                )}
                            />
                        </Row>

                        {togglePasswordMask && <Row className="mt-2">
                            <Controller
                                control={control}
                                name="confirmPassword"
                                render={({field}) => {
                                    return <SPassword
                                        {...field}
                                        style={{width:"100%"}}
                                        placeholder="Подтверждение пароля"
                                    />
                                }}
                            />
                        </Row>}
                        <Row>
                            <div style={{display:"flex", alignItems:"center", width:"100%", justifyContent:"center" }}>
                                <p>Даю согласие на обработку песональных данных</p>
                                <div className={"pl-2"}/>
                                <Checkbox checked={consent} onClick={()=>{setConsent(!consent)}} />
                            </div>
                        </Row>
                        {consent && <Row className="mt-2">
                            <Button type="submit" style={{width:"100%"}} label="Регистрация"/>
                        </Row>}
                        <Row className="mt-2">
                            <Button type="button" style={{width:"100%"}}
                                label="Ко входу в систему"
                                onClick={()=>{history.push("/login")}}
                            />
                        </Row>
                        <Row className="mt-2">
                            <a
                                href={manual}
                                download
                                style={{textDecoration:"none", width:"100%"}}
                            >
                                <Button type="button" style={{width:"100%"}} label="Инструкция"/>
                            </a>
                        </Row>
                    </form>
                </PasswordCard>

            </div>
        </div>
    );
}

export default Register;