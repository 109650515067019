import React, {useContext, useRef, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {PasswordCard, Row} from "./Common/Styles";
import logo from "../images/logo4.png"
import {AppContext} from "./AppContext";
import {Toast} from "primereact/toast";
import {useHistory} from "react-router-dom";
import manual from "../images/01_РИ _Врача_.pdf";
import PasswordEditor from "./Common/PasswordEditor";

function Login(props) {
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const context = useContext(AppContext);
    const toast = useRef(null)
    const history = useHistory()

    const onLogin = (e) =>{
        e.preventDefault();
        context.apiService.login({userName, password}).then(
            (data)=>{
                if (data) {
                    history.push("/")
                } else
                    toast.current.show({severity: 'error', summary: 'Внимание', detail: "Неверный пароль или имя пользователя", life: 3000});
            }
        )
    }

    return (
        <div style={{display:"flex", height:"100%", minHeight:"70vh"}}>
            <Toast ref={toast} position="top-center"/>

            <PasswordCard style={{width:"260px", height:"500px", margin:"auto"}}>
            <form onSubmit={onLogin}>
                <Row>
                    <img src={logo}  alt={"Logo"} style={{width:"100%"}}/>
                </Row>
                <Row className="mt-2">
                    <InputText
                        style={{width:"100%"}}
                        placeholder="Имя пользователя"
                        value={userName}
                        onChange={(e)=>{
                            setUserName(e.target.value)
                        }}
                    />
                </Row>
                <Row className="mt-2">
                    <PasswordEditor
                        value={password}
                        placeholder="Пароль"
                        onChange={(e)=>{setPassword(e.target.value)}}
                        toggleMask
                    />
                </Row>

                <Row className="mt-2">
                <Button type="submit" style={{width:"100%"}} severity={"success"} label="Войти"/>
                </Row>
                <Row className="mt-2">
                    <Button type="button" style={{width:"100%"}} label="Восстановить пароль"
                            onClick={()=>{history.push("/restore_pass")}}
                    />
                </Row>
                <Row className="mt-2">
                    <Button type="button" style={{width:"100%"}} label="Регистрация"
                        onClick={()=>{history.push("/register")}}
                    />
                </Row>
                <Row className="mt-2">
                    <a
                        href={manual}
                        download
                        style={{textDecoration:"none", width:"100%"}}
                    >
                        <Button type="button" style={{width:"100%"}} label="Инструкция"/>
                    </a>
                </Row>
            </form>
            </PasswordCard>

        </div>
    );
}

export default Login;
