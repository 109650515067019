import Axios from "axios"
import configData from '../config.json'
import {saveAs} from "file-saver"



class ApiService {
    constructor(options = {}) {

        this.logoutCallback = options.logOut
        this.client = options.client || Axios.create({baseURL:`${configData.SERVER_URL}`})
        this.client.interceptors.request.use(
            config =>{
                if (!sessionStorage.getItem("token")){
                    return config
                }
                const _config = {
                    headers: {},
                    ...config
                }
                _config.headers.Authorization = `Bearer ${sessionStorage.getItem("token")}`
                return _config
            },
            error => Promise.reject(error)
        )

        this.client.interceptors.response.use(
            r => r,
            async error => {
                if (
                    !sessionStorage.getItem("refresh_token") ||
                    error.response.status !== 401 ||
                    error.config.retry
                ){
                    throw error;
                }
                try{
                    const {data} = await this.client.post(
                        "/user/refresh_token",
                        {refreshToken: `Bearer ${sessionStorage.getItem("refresh_token")}`}
                    )
                    sessionStorage.setItem("token", data.token)
                    sessionStorage.setItem("refresh_token", data.refreshToken)

                    const newRequest ={
                        ...error.config,
                        retry: true
                    }
                    return this.client(newRequest)

                } catch (e){
                    this.logoutCallback()
                    //if (this.history) history.push("/login")
                    // sessionStorage.setItem("token", undefined)
                    // sessionStorage.setItem("refresh_token", undefined)
                    // throw e
                }
            }
        )
    }

    downloadPrintForm = (url) =>{
        this.getPrintForm(url).then((response) => {
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader];
            const effectiveFileName = (suggestedFileName === undefined
                ? "PrintForm.pdf"
                : suggestedFileName);
            saveAs(response.data, effectiveFileName);
        }).catch((response) => {
            console.error("Не могу подготовить печатную форму", response);
        });
    }

    async login({userName, password}){
        try {
            const formData = new FormData()
            formData.append("username", userName)
            formData.append("password", password)

            const {data} = await Axios.post(`${configData.SERVER_URL}/user/login`, formData, {
                headers:{
                    'Content-Type': 'multipart/form-data'
                }
            })

            sessionStorage.setItem("token", data.token)
            sessionStorage.setItem("refresh_token", data.refreshToken)
            return true
        } catch (err){
            return false
        }
    }

    checkPasswordStrength(password) {

        const minLength = 6;
        const hasUpperCase = /[A-Z,А-Я]/;
        const hasLowerCase = /[a-z, a-я]/;
        const hasNumbers = /\d/;
        const hasNonalphas = /\W/;

        let strength = 0;
        if (password.length <= minLength) {
            return 0
        }

        if (hasUpperCase.test(password)) strength += 1;
        if (hasLowerCase.test(password)) strength += 1;
        if (hasNumbers.test(password)) strength += 1;
        if (hasNonalphas.test(password)) strength += 1;

        if (password.length > 12) strength += 1;
        if (password.length > 16) strength += 1;

        return strength
    }

    async restorePassword(userNameOrEmail){
        return Axios
            .post(`${configData.SERVER_URL}/user/restore_password`, {userNameOrEmail})
            .then(res=>res.data)
    }

    async checkResetLink(token){
        return Axios
            .get(`${configData.SERVER_URL}/user/check_reset_link?`,{params:{token:token}})
            .then(res=>res.data)
    }

    async resetPassword(token, newPassword){
        return Axios
            .post(`${configData.SERVER_URL}/user/reset_password`, {token:token, newPassword:newPassword})
            .then(res=>res.data)
    }

    sign_up(registrationData){
        return  Axios
            .post(`${configData.SERVER_URL}/user/sign-up`, registrationData)
            .then(res=> res.data)
    }

    logout(){
        //this.token = null
        //this.refreshToken = null
        this.logoutCallback()
    }

    changePassword(user, password){
        return this.client.post("api/users/change_password", {id:user.id, password:password})
            .then(res => res.data)
    }

    changeUserPassword(user, old_password, new_password){
        return this.client.post(
            "user/change_password",
            {id:user.id, oldPass:old_password, newPass:new_password}
        )
        .then(res => res.data)
    }

    getUser(token){
        return this.client.post("user/details",{refreshToken:token}).then(res => res.data)
    }

    getPrintForm(url){
        return this.client.get(url, {responseType: 'blob'})
    }

    getPrintFormsList(category){
        return this.client.get(`api/print_forms?category=${category}`).then(res =>res.data)
    }

    getFuture(url, params){
        const {page, rows, first, ...other} = params
        url += `page=${page}&`+
                `size=${rows}`
        for (let i in other) {
            if(other[i]!=="")
                url+='&'+i+`=${other[i]}`
        }
        return this.client.get(url).then(res => res.data)
    }

    async getAsyncFeature(url, params){
        const {page, rows, first, ...other} = params
        url += `page=${page}&`+
            `size=${rows}`
        for (let i in other) {
            if(other[i]!=="")
                url+='&'+i+`=${other[i]}`
        }
        return await this.client.get(url)
    }

    getAth(params){
        return this.getFuture(`api/ath?`, params)
    }

    getPatients(params){
        return this.getFuture(`api/patients?`, params)
    }

    getPersons(params){
        return this.getFuture(`api/persons?`, params)
    }

    savePatient(patient){
        return this.client.post(`api/patients`, patient)
            .then(res => res.data)
    }

    getUsers(params){
        let url = `/api/users?page=${params.page}&`+
            `size=${params.rows}`
        if (params.login) url += `&login=${params.login}`
        return this.client.get(url).then(
            res=>{return res.data}
        )
    }

    saveUser(user){
        return this.client.post(`api/users`,user)
            .then(res => res.data)
    }

    deleteUser(user){
        return this.client.delete(`api/users/${user.id}`)
            .then(res => res.data)
    }

    getMedicalOrs(params){
        return this.getFuture("api/medical_orgs?", params)
    }

    getAreas(params){
        return this.getFuture("api/area?", params)
    }

    saveMedicalOrg(medicalOrg){
        return this.client.post(`api/medical_orgs`,medicalOrg)
            .then(res => res.data)
    }

    deleteMedicalOrg(medicalOrg){
        return this.client.delete(`api/medical_orgs/${medicalOrg.id}`)
            .then(res => res.data)
    }

    savePharmacy(pharmacy){
        return this.client.post(`api/pharmacies`, pharmacy)
            .then(res=>res.data)
    }

    deletePharmacy(pharmacy){
        return this.client.delete(`api/pharmacies/${pharmacy.id}`)
            .then(res => res.data)
    }

    getPharmacies(params){
        return this.getFuture("/api/pharmacies?", params)
    }

    getOrs(params){
        return this.getFuture("adm/api/orgs?", params)
    }

    getUserRoles(){
        let url = `/api/users/roles`
        return this.client.get(url).then(
            res=>{return res.data}
        )
    }

    getAllAreas(){
        let url = `/api/areas`
        return this.client.get(url)
    }

    getRp(params){
        return this.getFuture("api/rp?", params)
    }

    getRpByID(id){
        return this.client.get(`api/rp/${id}`).then(res =>res.data)
    }

    getRpNames(params){
        return this.getFuture("api/rp/getNames?", params)
    }

    saveRp(rp){
        return this.client.post(`api/rp`, rp)
            .then(res => res.data)
    }

    deleteRp(rp){
        return this.client.delete(`api/rp/${rp.id}`)
            .then(res => res.data)
    }

    getRpComponents(params){
        return this.getFuture(`api/rp_component?`, params)
    }

    saveRpComponent(component){
        return this.client.post(`api/rp_component`, component)
            .then(res => res.data)
    }

    deleteRpComponent(component){
        return this.client.delete(`api/rp_component/${component.id}`)
            .then(res => res.data)
    }

    getPrescriptions(params){
        return this.getFuture(`api/prescriptions?`, params)
    }

    savePrescription(prescription){
        return this.client.post(`api/prescriptions`, prescription)
            .then(res => res.data)
    }

    getMonitoringByPrescription(id){
        return this.client.get(`api/monitoring/prescription/${id}`).then(res => res.data)
    }

    getPharmaTypes(){
        return this.client.get(`api/pharma_type/`).then(res=>res.data)
    }

    savePharmaType(pharmaType){
        return this.client.post(`api/pharama_type/`, pharmaType)
            .then(res => res.data)
    }

    deletePharmaType(pharmaType){
        return this.client.delete(`api/pharma_type/${pharmaType.id}`)
            .then(res => res.data)
    }

    saveMonitoring(monitoring){
        return this.client.post(`api/monitoring/`, monitoring)
            .then(res => res.data)
    }

    sendToPharmacy(prescription){
        return this.client.post('api/prescriptions/send_to_pharmacy', prescription)
            .then(res =>res.data)
    }

    getRpLecForms(params){
        return this.getFuture(`api/rp_lec_form?`, params)
    }

    saveRpLecForms(component){
        return this.client.post(`api/rp_lec_form`, component)
            .then(res => res.data)
    }

    deleteRpLecForms(lecForm){
        return this.client.delete(`api/rp_lec_form/${lecForm.id}`)
            .then(res => res.data)
    }

    getSpeciality(params){
        return this.getFuture(`api/speciality?`, params)
    }

    getRpTypeList(params){
        return this.getFuture(`api/rp_type?`, params)
    }

    getRpQuantTypes(){
        return this.client.get(`api/rp_quant_type`).then(res =>res.data)
    }

    getAbbreviation(){
        return this.client.get(`api/rp_abbreviation`).then(res =>res.data)
    }

    getMkb10(params){
        return this.getFuture(`api/mkb10?`, params)
    }

}

export default new ApiService()
//export default connect(mapStateToProps)(ApiService)
