import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm, useFormState} from "react-hook-form";
import {Panel} from "primereact/panel";
import styled from "styled-components";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import EmptyItem from "../Common/EmptyItem";
import {Map, Placemark, SearchControl} from '@pbe/react-yandex-maps';

const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns:150px 1fr; 
  grid-row-gap: 0.5rem;
`
const FullWidthInputText = styled(InputText)`
  width: 100%    
`

const HCenteredContainer = styled.div`
    margin-top: 0.5rem;
    text-align: center;
`

function Org(props) {

    const {org, onSaveOrg} = props

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        /*watch, formState:{errors}*/} = useForm({})
    const {isDirty} = useFormState({control})
    const [isReady, setIsReady] = useState(false);

    const placer = useRef();
    const searcControl = useRef();
    // const yamaps = useYMaps(['Map']);

    useEffect(() => {
        reset({...org})
        setIsReady(org)
    }, [org, reset]);

    if (!org) return (
        <div>
            <EmptyItem label="Выберите организацию для просмотра" height={"400px"}/>
        </div>
    )

    return (
        <form onSubmit={handleSubmit(onSaveOrg)}>
            <div>
                {isReady && <Panel header="Медучреждение" className="mr-2">
                    <FieldsGrid>
                        <div>Название</div>
                        <div>
                            <Controller
                                name = "organization.name"
                                control={control}
                                rules={{ required: 'Наименование обязателено к заполнению' }}
                                render={({field, fieldState})=>(
                                    <FullWidthInputText
                                        autoComplete="Off"
                                        {...field}
                                        className={classNames({ 'p-invalid': fieldState.invalid })}
                                    />
                                )}
                            />
                        </div>
                        <div>Адрес</div>
                        <div>
                            <Controller
                                name = "organization.address"
                                control={control}
                                rules={{ required: 'Адрес обязателено к заполнению' }}
                                render={({field, fieldState})=>(
                                    <FullWidthInputText
                                        autoComplete="Off"
                                        {...field}
                                        className={classNames({ 'p-invalid': fieldState.invalid })}
                                    />
                                )}
                            />
                        </div>
                        <div>Телефон</div>
                        <div>
                            <Controller
                                name = "organization.phone"
                                control={control}
                                rules={{ required: 'Телефон обязателено к заполнению' }}
                                render={({field, fieldState})=>(
                                    <FullWidthInputText
                                        autoComplete="off"
                                        {...field}
                                        className={classNames({ 'p-invalid': fieldState.invalid })}
                                    />
                                )}
                            />
                        </div>
                        <div>Email</div>
                        <div>
                            <Controller
                                name = "organization.email"
                                control={control}
                                rules={{ required: 'Email обязателено к заполнению' }}
                                render={({field, fieldState})=>(
                                    <FullWidthInputText
                                        autoComplete="off"
                                        value={field.value || ""}
                                        onChange={field.onChange}
                                        className={classNames({ 'p-invalid': fieldState.invalid })}
                                    />
                                )}
                            />
                        </div>
                        <div>Координаты</div>
                        <div>
                            <Controller
                                name = "organization.coords"
                                control={control}
                                render={({field, fieldState})=>(
                                    <FullWidthInputText
                                        autoComplete="off"
                                        value={field.value || ""}
                                        onChange={ (e)=>{
                                            setValue(
                                                "organization.coords",
                                                e.target.value.split(",").map((e)=>parseFloat(e)),
                                                { shouldDirty: true }
                                            )
                                        }}
                                        className={classNames({ 'p-invalid': fieldState.invalid })}
                                    />
                                )}
                            />
                        </div>
                    </FieldsGrid>
                    <div className="mt-2">
                        <Controller
                            name="organization.coords"
                            control={control}
                            render ={({field, fieldState})=>(
                            <Map
                                width="100%"
                                height="300px"
                                onClick={(e)=>{
                                    setValue("organization.coords", e.get('coords'))
                                    // yamaps.geocode(e.get('coords')).then(res=>console.log(res))
                                }}
                                state={{ center: field.value || [55.752490, 37.623205], zoom: 18 }}
                                >
                                    {field.value && <Placemark
                                            onDragend={(e)=>{
                                                setValue("organization.coords", placer.current.geometry.getCoordinates())
                                            }}
                                            instanceRef={placer} options={{ draggable: true,}}
                                            geometry={field.value}
                                    />}

                                    <SearchControl
                                        instanceRef={searcControl}
                                        onResultselect={(e)=>{
                                            searcControl.current.getResult(e.get('index')).then((res)=>{
                                                setValue("organization.coords", res.geometry.getCoordinates(), { shouldDirty: true })
                                            })
                                        }}
                                        options={{noPlacemark: true, float: "right" }}
                                    />
                            </Map>
                        )} />
                    </div>
                    {isDirty && <HCenteredContainer>
                        <Button label="Сохранить изменения" type="submit" />
                    </HCenteredContainer>}
                </Panel> }
            </div>
        </form>
    );
}

export default Org;
