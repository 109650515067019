import React from 'react'
import {observer} from "mobx-react-lite";
import {usePrescriptionsStore} from "../../../store/states/PrescriptionsStore";
import {useMonitoringStore} from "../../../store/states/MonitoringStore";
import {BlockUI} from "../BlockUI";
import {ItemsGridStyled} from "./ItemsGrid.styled";
import {InputTextarea} from "primereact/inputtextarea";
import {CenteredLabelStyled} from "../CenteredItems.styled";
import {Button} from "primereact/button";
import NumberEditor from "../NumberEditor";

function MonitoringEditor(props) {
    const pescriptonStore = usePrescriptionsStore()
    const store = useMonitoringStore()
    store.init(pescriptonStore)

    return (
        <div>
            <BlockUI blocking={store.isLoading || !store.item}>

                <ItemsGridStyled className={"mt-2"}>
                    <CenteredLabelStyled>Пациент:</CenteredLabelStyled>
                    <div>{`${store.patient?.fullName}`}</div>
                    <CenteredLabelStyled>Пол:</CenteredLabelStyled>
                    <div>{`${store.patient?.person.gender} `}</div>
                    <CenteredLabelStyled>Вес:</CenteredLabelStyled>
                    <NumberEditor
                        value={Number(store.patient?.person.weight)}
                        onChange={(e) => store.patient?.person.setWeight(Number(e.target.value))}
                    />
                </ItemsGridStyled>

                <ItemsGridStyled className={"mt-2"}>
                    <CenteredLabelStyled>Диагноз:</CenteredLabelStyled>
                    <InputTextarea
                        value={store.item?.diagnosis}
                        onChange={(e)=>{
                            store.item.setDiagnosis(e.target.value)
                        }}
                    />
                    <CenteredLabelStyled>Осложнения:</CenteredLabelStyled>
                    <InputTextarea
                        value={store.item?.complications}
                        onChange={(e)=>{
                            store.item.setComplications(e.target.value)
                        }}
                    />
                    <CenteredLabelStyled>Сопутствующий диагноз:</CenteredLabelStyled>
                    <InputTextarea
                        value={store.item?.comorbidity}
                        onChange={(e)=>{
                            store.item.setComorbidity(e.target.value)
                        }}
                    />
                    <CenteredLabelStyled>Опрос и обследование до ЭЛП:</CenteredLabelStyled>
                    <InputTextarea
                        value={store.item?.interview}
                        onChange={(e)=>{
                            store.item.setInterview(e.target.value)
                        }}
                    />
                    <CenteredLabelStyled>Опрос и обследование после ЭЛП:</CenteredLabelStyled>
                    <InputTextarea
                        value={store.item?.resultInterview}
                        onChange={(e)=>{
                            store.item.setResultInterview(e.target.value)
                        }}
                    />
                    <CenteredLabelStyled>Нежелательные явления в ходе терапии ЭЛП:</CenteredLabelStyled>
                    <InputTextarea
                        value={store.item?.sideEffects}
                        onChange={(e)=>{
                            store.item.setSideEffects(e.target.value)
                        }}
                    />
                    <CenteredLabelStyled>Заметки:</CenteredLabelStyled>
                    <InputTextarea
                        value={store.item?.notes}
                        onChange={(e)=>{
                            store.item.setNotes(e.target.value)
                        }}
                    />
                </ItemsGridStyled>
                <div style={{textAlign: "center"}} className={"pt-2"}>
                    <Button
                        severity={"success"}
                        className={"mr-2"}
                        label={"Сохранить изменения"}
                        visible={store?.isDirty}
                        onClick={()=>{store.save()}}
                    />
                    <Button label={"Завершить наблюдение"} />
                </div>
            </BlockUI>
        </div>
)
}

export default observer(MonitoringEditor)
