import React, {useEffect, useState} from 'react'
import {FilterMatchMode} from "primereact/api";
import {BlockUI} from "../../Common/BlockUI";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {observer} from "mobx-react-lite";
import EditorDialog from "./EditorDialog";
import {Button} from "primereact/button";
import {getSnapshot} from "mobx-state-tree";
import {Dialog} from "primereact/dialog";
import {useLecFormsStore} from "../../../store/states/LecFormsStore";
import {ClearButtonOutlined, EditButtonOutlined} from "../../Common/Styles";

function LecForms()
{
    const store = useLecFormsStore()
    const [params, setParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })

    const [filters, setFilters] = useState({
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [editorVisible, setEditorVisible] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [lecForm, setLecForm] = useState()

    useEffect(()=>{
        let isMounted = true
        const loadData = () =>{
            if (isMounted)
                store.fetch({...params})
        }
        loadData()
        return ()=>{isMounted=false}
    },[store, params])

    const onChangePage = (e)=>{
        setParams({...params, first:e.first, rows:e.rows, page: e.page })
    }

    const ifNull = (value, def) =>{
        return value ? value : def
    }

    const onFilter = (e) =>{
        setFilters(e.filters)
        setParams({
            ...params,
            name:ifNull(e.filters.name.value,""),
        })
    }

    const onSaveLecForm =(lecForm)=>{
        store.saveAndSelect(lecForm)
    }

    const editTemplate = (rowData, options) => {
        return <EditButtonOutlined
            style={{width:"20px",height:"20px"}}
            type="button"
            icon='pi pi-pencil'
            className="p-button-sm p-button-text"
            onClick={()=>{setLecForm(getSnapshot(rowData)); setEditorVisible(true)}}
        />;
    };

    const deleteTemplate = (rowData, options) => {
        return <ClearButtonOutlined
            style={{width:"20px",height:"20px"}}
            type="button"
            icon='pi pi-trash'
            severity="danger"
            className="p-button-sm p-button-text"
            onClick={()=>{setLecForm(getSnapshot(rowData)); setShowDeleteDialog(true)}}
        />;
    };

    const deleteDialogFooterContent = (
        <div>
            <Button label="Да" icon="pi pi-check"  severity="danger" onClick={() =>{
                store.deleteItem(lecForm)
                setShowDeleteDialog(false)
            }} />
            <Button label="Нет" icon="pi pi-check" onClick={() => setShowDeleteDialog(false)} autoFocus />
        </div>
    );

    return (
        <div>
            <EditorDialog
                visible={editorVisible}
                setVisible={setEditorVisible}
                component={lecForm}
                onSaveLecForm={onSaveLecForm}
            />

            <Dialog
                header="ВНИМАНИЕ"
                visible={showDeleteDialog}
                onHide={()=>{setShowDeleteDialog(false)}}
                footer={deleteDialogFooterContent}
            >
                <div>
                    Удалить лекарственную форму:
                </div>
                <div>
                    <strong>{lecForm?.name}?</strong>
                </div>
            </Dialog>

            <div className="ml-4 mr-4">
                <BlockUI blocking={store.isLoading}>
                    <DataTable
                        value={store.items}
                        filters={filters}
                        filterDisplay="row"
                        selection={store.selected}
                        selectionMode="single"
                        onSelectionChange={e => {
                            if (e.value) store.setSelected(e.value);
                        }}
                        onFilter={onFilter}
                    >
                        <Column filter header="Лекарственная форма" field="name"/>
                        <Column style={{ flex: '0 0 4rem', width:"10px" }} body={editTemplate}></Column>
                        <Column style={{ flex: '0 0 4rem', width:"10px" }} body={deleteTemplate}></Column>
                    </DataTable>
                    <Paginator rows={params.rows} totalRecords={store.total}
                               first={params.first} onPageChange={onChangePage}
                               rowsPerPageOptions={[10, 20, 30]}
                               className="justify-content-end"
                               leftContent={()=>(
                                   <Button
                                       icon="pi pi-plus"
                                       rounded
                                       onClick={()=>{
                                           setLecForm({})
                                           setEditorVisible(true)
                                       }}
                                   />
                               )}
                    />

                </BlockUI>
            </div>
        </div>
    )
}

export default observer(LecForms)
