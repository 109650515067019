import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react-lite";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Label} from "../../Common/Styles";
import {Button} from "primereact/button";

const grid = {display: "grid", gridTemplateColumns: "6rem 1fr", gridRowGap: "0.5rem", margin: "4px"}

function EditorDialog(props) {

    const {visible, setVisible, component, onSaveComponent} = props
    const [item, setItem] = useState({})

    useEffect(() => {
        if (component)
            setItem(component)
    }, [component]);

    const footerContent = (
        <div>
            <Button label="Сохранить" icon="pi pi-check" onClick={() => {
                onSaveComponent(item)
                setVisible(false)
            }} type="submit" />
            <Button label="Отмена" icon="pi pi-trash" severity="danger" onClick={() => setVisible(false)} autoFocus />
        </div>
    );

    return (
        <div>
            <Dialog
                header="Компонент"
                footer={footerContent}
                visible={visible}
                onHide={()=>{setVisible(false)}}
            >
                <form>
                <div style={grid}>
                    <Label>Латынь:</Label>
                    <div>
                        <InputText
                           value={item?.name || ""}
                           onChange={(e)=>{
                               setItem({...item,name:e.target.value})
                           }}
                        />
                    </div>
                </div>
                <div style={grid}>
                    <Label>Русский: </Label>
                    <div>
                        <InputText
                            value={item?.ru_name || ""}
                            onChange={(e)=>{
                                setItem({...item, ru_name:e.target.value})
                            }}
                        />
                    </div>
                </div>
                <div style={grid}>
                    <Label>Английский: </Label>
                    <div>
                        <InputText
                            value={item.en_name || ""}
                            onChange={(e)=>{
                                setItem({...item, en_name:e.target.value})
                            }}
                        />
                    </div>
                </div>
                </form>
            </Dialog>
        </div>
    )
}

export default observer(EditorDialog)
