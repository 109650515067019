import React from 'react'
import {Card} from "primereact/card";
import {getProperty} from "./utils";
import {Button} from "primereact/button";

function ReadOnlyCard(props) {
    const {value, columns, rowIndex, onRowDelete, onRowEdit} = props;
    return (
        <Card className={"mt-2"}>
            {columns.map((column, index) => {
                return <div style={{display:"grid", gridTemplateColumns: "1fr 2fr"}} key={index}>
                    <div style={{fontWeight:"bold"}}>{column.header ? column.header+":" : ""}</div>
                    <div>{
                        column.body ? column.body(value) : getProperty(value, column.field)
                    }</div>
                </div>
            })}
            <div className={"mt-2"} style={{display:"grid", gridTemplateColumns: "100px auto"}}>
                {/*<div></div>*/}
                {/*<div style = {{display:"grid", gridTemplateColumns: "40px 40px"}} >*/}
                    {columns.map((column, index) => {
                        if (column.rowEditor) return <div style={{textAlign:"left"}} key={index}>
                          <Button icon="pi pi-pencil" text rounded
                                  onClick={() => {if (onRowEdit) onRowEdit({index:rowIndex, data:value})}}
                          />
                        </div>
                        if (column.rowDeleter) return <div style={{textAlign:"right"}} key={index}>
                            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger"
                                onClick={() => {if (onRowDelete) onRowDelete(value)}}
                            />
                        </div>
                    })}
                {/*</div>*/}
            </div>
        </Card>
    )
}

export default ReadOnlyCard
