import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import './index.scss';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom";
import {store} from "./store/AppState";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <DevSupport ComponentPreviews={ComponentPreviews}
                            useInitialHook={useInitial}
                >
                    <App/>
                </DevSupport>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
export {CenteredItemStyled} from "./components/Common/CenteredItems.styled";
export {FullCenteredItemStyled} from "./components/Common/CenteredItems.styled";