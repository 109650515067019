import { types } from "mobx-state-tree"
import {Person} from "./Person";
import {Organization} from "./Organization";
import {Role} from "./Role";
import {Area} from "./Area";
import {Speciality} from "./Speciality";

export const User = types.model({
    id: types.number,
    login: types.string,
    regDate: types.maybeNull(types.string),
    person: types.maybeNull(Person),
    organization: types.maybeNull(Organization),
    roles: types.array(Role),
    area: types.maybeNull(Area),
    speciality: types.maybeNull(Speciality),
    cert: types.maybeNull(types.string)
}).views(self => ({
    get fullName(){
        return self.person ? `${self.person.surname} ${self.person.name}` : self.login
    },
    get isAdmin(){
        if (self.roles)
            return self.roles.findIndex(i=>i.name==="ROLE_ADMIN") !== -1
        return false
    },
    get isDoctor(){
        if (self.roles)
            return self.roles.findIndex(i=>i.name==="ROLE_DOCTOR") !== -1
        return false
    },
    get isPharmacist(){
        if (self.roles)
            return self.roles.findIndex(i=>i.name==="ROLE_PHARMACIST") !== -1
        return false
    },
    get reg_date(){
        return new Date(self.regDate)
    }
}))

// export const UserApiWrapper = types.snapshotProcessor(User, {
//     preProcessor(snapshot) {
//         return {
//             ...snapshot,
//             person: snapshot.person ? {
//                 ...snapshot.person,
//                 birthDate: parseISO(snapshot.person.birthDate)
//             } : snapshot.person
//         }
//     },
//     postProcessor(snapshot) {
//         return {
//             ...snapshot,
//             person:{...snapshot.person,
//                 birthDate: snapshot.person?.birthDate ? formatISO(snapshot.person.birthDate) : null
//             }
//         }
//     }
// })
