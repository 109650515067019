import React, {useEffect, useState} from 'react';
import {Dialog} from "primereact/dialog";
import PropTypes from "prop-types";
import {rpListState} from "../../store/models/RpList";
import {DataTable} from "primereact/datatable";
import {observer} from "mobx-react-lite";
import {Column} from "primereact/column";
import SplitPane from "react-split-pane";
import "../Resizer.css"
import 'react-block-ui/style.css';
import Rp from "../AppManager/Rp";
import LabeledSearchInput from "../Common/LabeledSearchInput";
import {Button} from "primereact/button";
import EmptyItem from "../Common/EmptyItem";
import {SearchPanelStyled} from "./SearchPanel.styled.js";
import {BlockUI} from "../Common/BlockUI";

function RpList(props) {
    const {visible, onHide, onSelect} = props
    const [params, setParams] = useState({
        first: 0,
        rows:10,
        page:0,
        name:"",
        ath_name:"",
        rp_type_name:""
    });

    useEffect(() => {
       rpListState.fetchRpList(params)
    }, [params]);

    const onChangePage = (e)=>{
        setParams({...params, first:e.first, rows:e.rows, page: e.page })
    }

    return (
        <div>
            <Dialog header={"Прописи"} visible={visible} onHide={onHide}
                    style={{width:"80%"}}
                    breakpoints={{ '960px': '80%', '641px': '100%' }}
            >
                <div>
                <SplitPane primary="first" defaultSize={450}  style={{position:"relative", minWidth:"1000px"}}>
                    <div >
                        <SearchPanelStyled header={"Фильтры"} style={{width:"100%"}}>
                            <LabeledSearchInput
                                value={params.name}
                                label="Наименование"
                                autoFocus
                                onChange = {(e)=>{
                                    setParams({...params, name:e.target.value, page:0})
                                }}
                            />
                            <LabeledSearchInput
                                value={params.ath_name}
                                label="АТХ"
                                onChange = {(e)=>{
                                    setParams({...params, ath_name:e.target.value, page:0})
                                }}
                            />
                            <LabeledSearchInput
                                value={params.rp_type_name}
                                label="Тип действия"
                                onChange = {(e)=>{
                                    setParams({...params, rp_type_name:e.target.value, page:0})
                                }}
                            />
                        </SearchPanelStyled>
                        <BlockUI  blocking={rpListState.isLoading} >
                            <DataTable className={"mt-2"}
                                value={rpListState.items}
                                emptyMessage="Нет записей"
                                lazy
                                paginator
                                first={params.first}
                                rows={params.rows}
                                totalRecords={rpListState.total}
                                selectionMode="single"
                                selection={rpListState.selectedItem}
                                onSelectionChange={(e)=>{
                                    if (e.value)
                                        rpListState.setSelected(e.value)
                                }}
                                onPage={onChangePage}
                            >
                                <Column field="name" header="Наименование"/>
                            </DataTable>
                        </BlockUI>
                    </div>
                    <div>
                        {rpListState.selectedItem && <div>
                            <Rp rp={rpListState.selectedItem} readOnly/>
                            <div className="mt-2" style={{textAlign:"center"}}>
                                <Button label="Выбрать" onClick={()=>{onSelect(rpListState.selectedItem)}}/>
                            </div>
                        </div>}
                        {!rpListState.selectedItem && <EmptyItem label="Выберите пропись для просмотра" height={"400px"}/>}
                    </div>
                </SplitPane>
                </div>
            </Dialog>
        </div>
    );
}

RpList.propTypes = {
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    onSelect: PropTypes.func
}

export default observer(RpList);
