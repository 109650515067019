import React from 'react';
import {Toolbar} from "primereact/toolbar";
import styled from "styled-components";
// import {Button} from "primereact/button";
import UserMenu from "../../UserMenu";
import manual from "../../../images/01_РИ _Врача_.pdf"
import {Button} from "primereact/button";
import configData from '../../../config.json'

// import {useHistory, useLocation} from "react-router-dom";

const TopToolbar = styled(Toolbar)`
  position: fixed;
  top: 0; 
  width: 100%; 
  z-index: 1;
`

function ToolBar(props) {
    // const history = useHistory()

    const leftContents = (
        <>
            <div>
                {configData.VERSION}
            </div>
        </>
    )
    const rightContents = (
        <>
            {
                <>
                    <a
                        href={manual}
                        download
                        style={{textDecoration:"none"}}
                    >
                        <Button label="Инструкция"/>
                    </a>


                    <div className="ml-2"><UserMenu/></div>
                </>
            }
        </>
    )
    return (
        <TopToolbar
            left={leftContents}
            right={rightContents}
        />
    );
}

export default ToolBar;
