import React, {useEffect, useState} from 'react';
import {Toolbar} from "primereact/toolbar";
import {useHistory, useLocation} from "react-router-dom";
import UserMenu from "../UserMenu";
import {TabMenu} from "primereact/tabmenu";

function ToolBar(props) {
    const {items} = props

    const history = useHistory()
    const location = useLocation()
    const [activeIndex, setActiveIndex] = useState(0);

    const onTabChange = (e) =>{
        history.push(items[e.index].page)
        setActiveIndex(e.index)
    }

    useEffect(() => {
        const path = location.pathname.endsWith("/") ? location.pathname.slice(0,-1) : location.pathname
        const index = items.findIndex(
            i=> i.page === path
        )
        setActiveIndex(index)
    }, [items, location.pathname]);


    const leftContents = (
        <>
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={onTabChange}/>

            {/*<Button*/}
            {/*    label="Пользователи"*/}
            {/*    icon="pi pi-user"*/}
            {/*    tabIndex={-1}*/}
            {/*    onClick={()=>{history.push("/adm")}}*/}
            {/*    className={location.pathname === "/adm" ? "p-button-outlined": "p-button-primary"}*/}
            {/*/>*/}
            {/*<Button*/}
            {/*    label="Прописи"*/}
            {/*    onClick={()=>{history.push("/adm/rp")}}*/}
            {/*    className={`p-ml-2 ${location.pathname === "/adm/rp" ? "p-button-outlined": "p-button-primary"}`}*/}
            {/*/>*/}

        </>
    )

    const rightContents = (
        <>
            <UserMenu />
        </>
    )

    return (
        <div>
            <Toolbar
                left={leftContents}
                right={rightContents}
                style={{position: "fixed", top: "0", width: "100%", zIndex: "1"}}
            />
        </div>
    );
}

export default ToolBar;
