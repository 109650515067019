import React, {useContext, useEffect} from 'react';
import ToolBar from "./ToolBar";
import {Route, Switch} from "react-router-dom";
import Users from "./Users";
import RpList from "./RpList";
import {AppContext} from "../AppContext";
import DeniedLabel from "./DeniedLabel";
import Orgs from "./Orgs";
import Pharmacies from "./Pharmacies";
import {appState} from "../../store/AppState";
import {User} from "../../store/models/User";
import Mkb10 from "./Mkb10";
import Components from "./Components";
import LecForms from "./LecForms";

const items = [
    {label: 'Пользователи', icon: 'pi pi-fw pi-users', page:"/admin"},
    {label: 'Медучреждения', icon: 'pi pi-fw pi-building', page:"/admin/org"},
    {label: 'Аптеки', icon: 'pi pi-fw pi-building', page:"/admin/pharmacy"},
    {label: 'Прописи', icon: 'pi pi-fw pi-calendar', page:"/admin/rp"},
    {label: 'МКБ10', icon: 'pi pi-fw pi-calendar', page:"/admin/mkb10"},
    {label: 'Компоненты', icon: 'pi pi-fw pi-calendar', page:"/admin/components"},
    {label: 'ЛФ', icon: 'pi pi-fw pi-calendar', page:"/admin/lec_forms"},
    // {label: 'Documentation', icon: 'pi pi-fw pi-file'},
    // {label: 'Settings', icon: 'pi pi-fw pi-cog'}
];

function AppManager(props) {

    const context = useContext(AppContext)

    const loadUserData = () =>{
        //setLoading(true)
        if (sessionStorage.getItem("refresh_token") !== undefined)
            context.apiService.getUser(sessionStorage.getItem("refresh_token"))
                .then(data =>{
                    appState.setUser(User.create(data))
                    //setLoading(false)
                })
                .catch(e=>{console.log(e)})
    }

    useEffect(()=>{
        loadUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const centered = {
    //     display:"flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     height: "200px",
    //     //border: "3px solid green"
    // }

    function RestrictedRoute({ children, ...rest }) {
        if (!appState.user?.roles || appState.user.roles===[]) return (<></>)
        return (
            <Route
                {...rest}
                render = {({ location }) =>
                    appState.user.roles.find(i=>i.level>=rest.level) ? (
                        children
                    ) : (
                        <DeniedLabel/>
                    )
                }
            />
        );
    }

    //if (appState.user?.roles && appState.user?.roles === []) return (<div>Загрузка...</div>)

    return (
        <div>
            <ToolBar items={items}/>
            <Switch>
                <RestrictedRoute path="/admin" exact level={10} ><Users/></RestrictedRoute>
                <RestrictedRoute path="/admin/org" exact level={10} ><Orgs/></RestrictedRoute>
                <RestrictedRoute path="/admin/pharmacy" exact level={10} ><Pharmacies/></RestrictedRoute>
                <RestrictedRoute path="/admin/rp" exact level={2}><RpList/></RestrictedRoute>
                <RestrictedRoute path="/admin/mkb10" exact level={2}><Mkb10/></RestrictedRoute>
                <RestrictedRoute path="/admin/components" exact level={2}><Components/></RestrictedRoute>
                <RestrictedRoute path="/admin/lec_forms" exact level={2}><LecForms/></RestrictedRoute>
            </Switch>
        </div>
    );
}

export default AppManager;
