import "./App.scss";
import "primeicons/primeicons.css";
//import "./themes/lara-light-indigo/theme.css"
//import 'primereact/resources/themes/saga-blue/theme.css';
//import "primereact/resources/themes/lara-light-indigo/theme.css"
//import "./scss/themes/lara/lara-light/indigo/theme.scss"
import "./scss/themes/saga/saga-blue/theme.scss"
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "./components/AppContext";
import Login from "./components/Login";
import ApiService from "./services/ApiService";
import AppManager from "./components/AppManager";
import DoctorUI from "./components/UI/DoctorUI";
import {appState} from "./store/AppState";
import {User} from "./store/models/User";
import UserMenu from "./components/UserMenu";
import Register from "./components/Register";
import PharmacyUI from "./components/UI/PharmacyUI";
import ResAPIDoc from "./components/ResAPIDoc";
import {YMaps} from "@pbe/react-yandex-maps";
import configData from './config.json'
import {propisi} from "./global";
import RestorePass from "./components/RestorePass";
import ResetPassword from "./components/ResetPassword";

const App = (props) => {


    const history = useHistory()
    const location = useLocation()
    const context = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(false);

    context.apiService = ApiService

    const loadUserData = (token) =>{
        setIsLoading(true)
        if (sessionStorage.getItem("refresh_token"))
            context.apiService.getUser(sessionStorage.getItem("refresh_token"))
            .then(data =>{
                appState.setUser(User.create(data))
                context.user = data
                setIsLoading(false)
            })
            .catch(e=>{
                history.push("/login")
            })
    }

    useEffect(()=>{
        loadUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStorage.getItem("token")])

    if (!sessionStorage.getItem("token") &&
        (location.pathname!=="/login") &&
        (location.pathname!=="/register") &&
        (location.pathname!=="/restore_pass") &&
        (!location.pathname.startsWith("/reset_password")) &&
        (location.pathname!=="/rest_api_doc")
    )
        history.push("/login")

    // if (user.roles){
    //     let admin = user.roles.findIndex(i=>i.name==="ROLE_ADMIN")
    //     if (admin!==-1 && !location.pathname.startsWith("/adm")) history.push("/adm")
    // }

    const user = appState.user

    function RestrictedRoute({ children, ...rest }) {
        if (!appState.user?.roles || appState.user.roles.length === 0) return (<></>)
        return (
            <Route
                {...rest}
                render = {({ location }) =>
                    appState.user.roles.find(i=>i.level===rest.level) ? (
                        children
                    ) : (
                        history.push("/")
                    )
                }
            />
        );
    }

    return (

        <div className="App">
            {/*<YMaps query={{apikey:"7ac17c0f-9fd1-450f-ab35-cd95b99e8971", load:"geolocation,geocode"}}>*/}
            <YMaps query={{apikey:`${configData.YAPI_KEY}`, load:"geolocation,geocode"}}>
            <div style={{marginTop: "60px"}}>

                <Switch>
                    <Route path="/login" exact component={Login} />
                    <Route path="/register" exact component={Register} />
                    <Route path="/restore_pass" exact component={RestorePass} />
                    <Route path="/reset_password/:token" component={ResetPassword}/>
                    <Route path="/" exact
                           render ={
                               (props) =>{
                                   if (user?.isAdmin) return (
                                       history.push("/admin")
                                   )
                                   if (user?.isDoctor) return (
                                       history.push("/doctor")
                                   )
                                   if (user?.isPharmacist) return (
                                       <PharmacyUI />
                                   )
                                   if (!isLoading) return (<div style={{textAlign:"center"}}>
                                       <UserMenu />
                                       Для Вас интерфейса пока нет!!!
                                   </div>)
                               }
                           }
                    />

                    <RestrictedRoute path="/admin" level={propisi.ADMIN} >
                        <AppManager {...props}/>
                    </RestrictedRoute>
                    <RestrictedRoute path="/doctor" level={propisi.DOCTOR_ROLE} >
                        <DoctorUI />
                    </RestrictedRoute>

                    <Route path="/rest_api_doc" exact component={ResAPIDoc} />
                    <Redirect to="/" />
                </Switch>
            </div>
            </YMaps>
        </div>
    );
}

export default App;

