import {types} from "mobx-state-tree";
import {Patient} from "../models/Patient";
import ApiService from "../../services/ApiService";

export const PatientState = types.model({
    patient: Patient,
    isLoading: false,
    error: ""
}).actions(self=>({
    fetchSuccess(data){
        self.patient = data
        self.error = false
        self.isLoading = false
    },
    fetchError(error){
        self.error = error.message
        self.isLoading = false;
    },
    save(){
        self.isLoading = true
        ApiService.savePatient(self.patient).then(data=>{
            self.fetchSucces(data)
        }).catch(error =>{
            self.fetchError(error)
        })
    }
})).views(self => ({
    get isDirty(){
        return self.patient.isDirty
    }
}))