import { types } from "mobx-state-tree"
import {Patient} from "./Patient";
import {MedicalOrg} from "./MedicalOrg";
import {Pharmacy} from "./Pharmacy";
import {PrescriptionItem} from "./PrescriptionItem";
import {parseISO} from "date-fns";
import {RpLecform} from "./RpLecform";
import {Person} from "./Person";
import {differenceInDays, addDays} from "date-fns"
import {Rp} from "./Rp";
import {Area} from "./Area";

export const PrescriptionCore = types.model({
    date: types.maybeNull(types.string),
    beginDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    chronic: types.maybeNull(types.boolean),
    patient: types.maybeNull(Patient),
    doctor: types.maybeNull(Person),
    pharmacist: types.maybeNull(Person),
    medicalOrg: types.maybeNull(MedicalOrg),
    pharmacies: types.array(Pharmacy),
    dtd: types.maybeNull(types.integer),
    mds: types.maybeNull(types.string),
    action: types.maybeNull(types.string),
    lecForm: types.maybeNull(RpLecform),
    rpName: types.maybeNull(types.string),
    items: types.array(PrescriptionItem),
    finished: types.maybeNull(types.boolean),
    price: types.maybeNull(types.number),
    rp: types.maybeNull(Rp),
    sentToPharmacy: types.maybeNull(types.boolean),
    area: types.maybeNull(Area)
}).actions(self=>({
    setPatient(patient){
        let setDirty = self.patient.id !== patient.id
        self.patient = patient
        if (!self.isDirty && setDirty)
            self.isDirty = true
    },
    setDoctor(doctor){
        self.doctor = doctor
        if (!self.isDirty)
            self.isDirty = true
    },
    setMedicalOrg(org){
        self.medicalOrg = org
        if (!self.isDirty)
            self.isDirty = true
    },
    setDate(date){
        if (typeof date === 'string' || date instanceof String)
            self.date = date //parseISO(date)
        else
            self.date = date.toISOString() //date

        if (!self.beginDate) self.beginDate = self.date
        if (!self.isDirty)
            self.isDirty = true
    },
    addPharmacy(pharmacy){
        self.pharmacies.push(pharmacy)
        if (!self.isDirty)
            self.isDirty = true
    },
    removePharmacy(pharmacy){
        self.pharmacies = self.pharmacies.filter(i=>(i.id !== pharmacy.id))
        if (!self.isDirty)
            self.isDirty = true
    },
    addItem(item){
        self.items.push(item)
        if (!self.isDirty)
            self.isDirty = true
    },
    removeItem(serialNumber){
        self.items = self.items.filter(i => (i.serialNumber !== serialNumber))
        if (!self.isDirty)
            self.isDirty = true
    },
    clearItems(){
        self.items = []
        if (!self.isDirty)
            self.isDirty = true
    },
    setMds(mds){
        self.mds = mds
        if (!self.isDirty)
            self.isDirty = true
    },
    setAction(action){
        self.action = action
        if (!self.isDirty)
            self.isDirty = true
    },
    setLecForm(lecForm){
        self.lecForm = lecForm
        if (!self.isDirty)
            self.isDirty = true
    },
    setRpName(name){
        self.rpName = name
        if (!self.isDirty)
            self.isDirty = true
    },
    setDTD(dtd){
        if (typeof dtd === 'string' || dtd instanceof String)
            self.dtd = Number(dtd)
        else
            self.dtd = dtd
        if (!self.isDirty)
            self.isDirty = true
    },
    setChronic(chronic){
        self.chronic = chronic
        if (!self.isDirty)
            self.isDirty = true
    },
    setDaysCount(count){
        if (count && count > 0 && self.beginDate)
            self.endDate = addDays(parseISO(self.beginDate), count).toISOString()
        else self.endDate = null
        if (!self.isDirty)
            self.isDirty = true
    },
    setBeginDate(date){
        if (typeof date === 'string' || date instanceof String)
            self.beginDate = date
        else
            self.beginDate = date.toISOString()
        if (!self.isDirty)
            self.isDirty = true
    },
    setEndDate(date){
        if (typeof date === 'string' || date instanceof String)
            self.endDate = date
        else
            self.endDate = date.toISOString()
        if (!self.isDirty)
            self.isDirty = true
    },
    setPrice(price){
        if (typeof price === 'string' || price instanceof String)
            self.price = Number(price)
        else
            self.price = price
        if (!self.isDirty)
            self.isDirty = true
    },
    setFinished(finished){
        self.finished = finished
    },
    setRp(rp){
        self.rp = rp
        if (!self.isDirty)
            self.isDirty = true
    },
    setSentToPharmacy(value){
        self.sentToPharmacy = value
        if (!self.isDirty)
            self.isDirty = true
    },
    setArea(value){
        self.area = value
        if (!self.isDirty)
            self.isDirty = true
    },
    setIsDirty(value){
        self.isDirty = value;
        if (!value) self.items.forEach(i => i.setIsDirty(value))
    }
})).volatile(self=>({
    isDirty: false
})).views(self => ({
    get daysCount(){
        if (self.beginDate && self.endDate)
            return differenceInDays(
                parseISO(self.endDate) ,
                parseISO(self.beginDate)
            )
        return ""
    },
    get prescription_date(){
        if (self.date)
            return parseISO(self.date)
        return undefined
    },
    get begin_date(){
        if (self.beginDate)
            return parseISO(self.beginDate)
        return undefined
    },
    get end_date(){
        if (self.endDate)
            return parseISO(self.endDate)
        return undefined
    },
    get content(){
        return self.items.reduce((acc, i)=>{return acc += (acc === "" ? "" : ", " ) + i.component.name },"")
    },
    get isChanged(){
        return self.isDirty || (self.items.find(i => i.isDirty) !== undefined)
    }
}))

export const Prescription = types.compose(
    types.model({
        id: types.identifierNumber,
    }),
    PrescriptionCore
)

export const EditablePrescription = types.compose(
    types.model({
        id: types.maybeNull(types.number),
    }),
    PrescriptionCore
)

