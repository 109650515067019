import {types} from "mobx-state-tree"
import {Mkb10} from "../models/Mkb10";
import ApiService from "../../services/ApiService";

export const Mkb10Store = types.model({
    items: types.array(Mkb10),
    selected: types.safeReference(Mkb10),
        isLoading: false,
    isDirty: false,
    total: types.maybe(types.number),
    error: "",
}).actions(self => ({
    setItems(items) {
        self.items = items
    },
    fetch(params) {
        self.isLoading = true;
        ApiService.getMkb10(params).then(data => {
            self.fetchSuccess(data)
        }).catch(e => {
            self.fetchError(e)

        })
    },
    fetchSuccess(data) {
        if (data.content) {
            //self.items = data.content
            self.setItems(data.content)
        }
        self.total = data.totalElements
        self.isLoading = false
    },
    fetchError(error) {
        self.error = error.message
        self.isLoading = false
    },
    setSelected(selected) {
        self.selected = selected
    },
    initAddNew() {
        self.selected = Mkb10.create()
    }
}))

let _store
export const useMkb10Store = () => {
    if (!_store) {
        _store = Mkb10Store.create({
            items: []
        })
    }
    return _store;
}