import {clone, types} from "mobx-state-tree"
import {Rp} from "./Rp";
import ApiService from "../../services/ApiService";

const RpListState = types.model({
    items: types.array(Rp),
    isLoading: false,
    selectedItem: types.maybe(Rp),
    total: types.maybeNull(types.integer),
    error:""
}).actions(self => ({
    fetchRpList(params){
        self.isLoading = true
        ApiService.getRp(params).then(data =>{
            self.fetchRpListSuccess(data)
        }).catch(e =>{
            console.log(e)
        })
    },
    fetchRpListSuccess(data){
        self.isLoading = false
        self.items=[]
        if (data.content){
            data.content.forEach(i=>{
                self.items.push(Rp.create(i))
            })
        }
        self.total = data.totalElements
    },
    fetchRpListError(error){
        self.isLoading = false
        self.error = error
    },
    setSelected(selected){
        self.selectedItem = clone(selected)
    },
    clearSelected(){
        self.selectedItem = undefined
    }
}))

export const rpListState = RpListState.create()
