import React, {useContext} from 'react';
import {AppContext} from "../AppContext";
import NameFilter from "./NameFilter";
import UniversalList from "../Common/UniversalList";
import PropTypes from "prop-types";

function SpecialityList(props) {

    const context = useContext(AppContext)

    return (
        <div>
            <UniversalList
                header="Специальности"
                visible={props.visible}
                //filtersTemplate={filterTemplate}
                filtersTemplate={NameFilter}
                //rowViewerTemplate={rowViewTemplate}
                //rowEditorTemplate={rowEditorTemplate}
                onHide={props.onHide}
                onGetData={context.apiService.getSpeciality.bind(context.apiService)}
                onSelectItem={props.onSelectItem}
                onEditRow={(row)=>{console.log(row)}}
                onDeleteRow={(row)=>{console.log(row)}}
                onAddRow={(row)=>{console.log(row)}}
            />
        </div>
    );
}

SpecialityList.propTypes = {
    onSelectItem: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired
}

export default SpecialityList;