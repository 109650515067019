import React, {useEffect, useRef, useState} from 'react'
import {observer} from "mobx-react-lite";
import {Dialog} from "primereact/dialog";
import LabeledSearchInput from "../Common/LabeledSearchInput";
import {SearchPanelStyled} from "../RpList/SearchPanel.styled";
import {rpListState} from "../../store/models/RpList";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {BlockUI} from "../Common/BlockUI";
import RpMobile from "./RpMobile";
import {Button} from "primereact/button";

function MobileRpList(props) {
    const {visible, onHide, onSelect} = props
    const [activePage, setActivePage] = useState(0)
    // const [pages, setPages] = useState([{
    //     label : "Поиск", command: () => {setActivePage(0); rpListState.clearSelected()}
    // }])
    const [params, setParams] = useState({
        first: 0,
        rows:10,
        page:0,
        name:"",
        ath_name:"",
        rp_type_name:""
    });
    const dialog = useRef();
    useEffect(() => {
        rpListState.fetchRpList(params)
    }, [params]);

    useEffect(() => {
        setActivePage(0)
    },[visible])

    useEffect(() => {
        dialog.current?.scrollIntoView();
    }, [activePage]);
    const onChangePage = (e)=>{
        setParams({...params, first:e.first, rows:e.rows, page: e.page })
    }

    return (
        <div>
            <Dialog header={"Прописи"} visible={visible} onHide={onHide}
                    style={{width: "80%"}}
                    breakpoints={{'960px': '80%', '641px': '100%'}}

            >
                {activePage === 1 &&
                    <div style={{position: "absolute", width: "auto", zIndex: "10", marginRight: "30px"}}>
                        <Button label="Поиск" icon="pi pi-arrow-left"
                                onClick={() => {
                                    setActivePage(0);
                                    rpListState.clearSelected()
                                }}
                        />

                    </div>

                }
                {activePage === 1 && <div ref={dialog} style={{height: "60px"}}></div>}
                {activePage === 0 && <div>
                <SearchPanelStyled header={"Фильтры"} style={{width: "100%"}}>
                        <LabeledSearchInput
                            value={params.name}
                            label="Наименование"
                            autoFocus
                            onChange={(e) => {
                                setParams({...params, name: e.target.value, page: 0})
                            }}
                        />
                        <LabeledSearchInput
                            value={params.ath_name}
                            label="АТХ"
                            onChange={(e) => {
                                setParams({...params, ath_name: e.target.value, page: 0})
                            }}
                        />
                        <LabeledSearchInput
                            value={params.rp_type_name}
                            label="Тип действия"
                            onChange={(e) => {
                                setParams({...params, rp_type_name: e.target.value, page: 0})
                            }}
                        />
                    </SearchPanelStyled>

                    <BlockUI  blocking={rpListState.isLoading}>
                        <DataTable className="mt-2"
                            value={rpListState.items}
                            emptyMessage="Нет записей"
                            lazy
                            paginator
                            first={params.first}
                            rows={params.rows}
                            totalRecords={rpListState.total}
                            selectionMode="single"
                            selection={rpListState.selectedItem}
                            onSelectionChange={(e) => {
                                if (e.value){
                                    rpListState.setSelected(e.value)
                                    setActivePage(1)
                                }
                            }}
                            onPage={onChangePage}
                        >
                            <Column field="name" header="Наименование"/>
                        </DataTable>
                    </BlockUI>
                </div>}
                {activePage === 1 && <div>
                    <RpMobile rp={rpListState.selectedItem}/>
                    <div className="mt-2" style={{textAlign: "center"}}>
                        <Button label="Выбрать" onClick={() => {
                            onSelect(rpListState.selectedItem)
                        }}/>
                    </div>
                </div>}

            </Dialog>
        </div>
    )
}

export default observer(MobileRpList)