import React from 'react';
import ToolBar from "./ToolBar";
import {appState} from "../../../store/AppState";
import {observer} from "mobx-react-lite";
import Patients from "./Patients";
import {Route, Switch} from "react-router-dom";
import DeniedLabel from "../../AppManager/DeniedLabel";
import {propisi} from "../../../global";
import PatientCard from "./PatientCard";

function DoctorUI(props) {

    function RestrictedRoute({ children, ...rest }) {
        if (!appState.user?.roles || appState.user.roles===[]) return (<></>)
        return (
            <Route
                {...rest}
                render = {({ location }) =>
                    appState.user.roles.find(i=>i.level===rest.level) ? (
                        children
                    ) : (
                        <DeniedLabel/>
                    )
                }
            />
        );
    }

    return (
        <div>
            <ToolBar />
            <Switch>
                <RestrictedRoute path="/doctor" exact level={propisi.DOCTOR_ROLE} >
                    <Patients store={appState.doctorUI}></Patients>
                </RestrictedRoute>
                <RestrictedRoute path="/doctor/patient/:id" exact level={propisi.DOCTOR_ROLE} >
                    <PatientCard store={appState.doctorUI}></PatientCard>
                </RestrictedRoute>
            </Switch>
        </div>
    );
}

export default observer(DoctorUI);
