import {types} from "mobx-state-tree";
import {PharmaType} from "./PharmaType";

export const MonitoringCore = types.model({
    diagnosis: "",
    complications: "",
    comorbidity: "",
    pharmaType: types.maybeNull(PharmaType),
    interview: "",
    resultInterview: "",
    sideEffects: "",
    notes:"",
    prescriptionId: types.number
}).volatile(self=>({
    isDirty: false
})).actions(self=>({
    setDiagnosis(diagnosis){
        self.diagnosis = diagnosis;
        if (!self.isDirty) self.isDirty = true;
    },
    setComplications(complications){
        self.complications = complications;
        if (!self.isDirty) self.isDirty = true;
    },
    setComorbidity(comorbidity){
        self.comorbidity = comorbidity
        if (!self.isDirty) self.isDirty = true;
    },
    setInterview(interview){
        self.interview = interview
        if (!self.isDirty) self.isDirty = true;
    },
    setPharmaType(pharmaType){
        self.pharmaType = pharmaType;
        if (!self.isDirty) self.isDirty = true;
    },
    setResultInterview(resultInterview){
        self.resultInterview = resultInterview
        if (!self.isDirty) self.isDirty = true;
    },
    setSideEffects(sideEffects){
        self.sideEffects = sideEffects;
        if (!self.isDirty) self.isDirty = true;
    },
    setNotes(notes){
        self.notes = notes
        if (!self.isDirty) self.isDirty = true;
    },
    clearIsDirty(){
        self.isDirty = false
    }
}))

export const Monitoring = types.compose(
    types.model({
        id: types.identifierNumber,
    }), MonitoringCore
)

export const EditableMonitoring = types.compose(
    types.model({
        id: types.maybeNull(types.number)
    }), MonitoringCore
)