import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm, Controller, useWatch} from "react-hook-form";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useHotkeys} from "react-hotkeys-hook";
import {InputMask} from "primereact/inputmask";
import {useMediaQuery} from "react-responsive";
import {parseISO} from "date-fns";
import {Dropdown} from "primereact/dropdown";
import DateEditor from "../Common/DateEditor/DateEditor";
import {classNames} from "primereact/utils";
import {AppContext} from "../AppContext";
import {Toast} from "primereact/toast";
import {Checkbox} from "primereact/checkbox";
import PropTypes from "prop-types";

function PersonEditor(props) {

    const {reset, control, handleSubmit, formState:{errors, /*isDirty*/}, setValue, watch} = useForm(
        {
            defaultValues: {
                id:'',
                person:{
                    name: '',
                    surname: '',
                    patronymic: '',
                    snils: '',
                    phone: '',
                    birthDate: '',
                    gender: '',
                    consent: ''
                }
            }
        }
    )

    const isOneLineEditorMode = useMediaQuery({ query: '(max-width: 700px)' })

    const grid = {display:"grid", gridTemplateColumns:"110px 1fr", marginTop:"0.5rem",  alignItems: "center"}
    const firstColumnGrid = {display:"grid", gridTemplateColumns:"110px 1fr", alignItems: "center"}
    const secondColumnGrid = {display:"grid", gridTemplateColumns:"4rem 1fr", alignItems: "center"}
    const genders = [{label:"М", value:"М"}, {label:"Ж", value: "Ж"}]
    //const [saving, setSaving] = useState(false);

    const context = useContext(AppContext);
    const toast = useRef();
    const autoFocusItem = useRef();
    const consent = useWatch({name:"person.consent", control, defaultValue:false});
    // const [isLoading, setIsLoading] = useState(false);
    const [params, setParams] = useState({page:0, rows:10});
    const person = watch("person")
    const [prev_snils, setPrevSnils] = useState(undefined)

    const showError = (data) => {
        toast.current?.show({severity:'error', summary: 'Внимание', detail:`Произошла ошибка во время сохранения записи! ${data}`, life: 3000});
    }

    const onSubmit = (patient) =>{
        context.apiService.savePatient(patient).then(data=>{
            props.onFinished(data)
        }).catch(data =>{
            if (data.response)
                showError(data.response.data.message)
        })
    }

    useHotkeys(
        "esc",
        (e) => {
            props.onFinished()
            e.preventDefault()
        },
        {enableOnTags: ["INPUT", "TEXTAREA", "SELECT"]}
    )

    useEffect(() => {
        if (!props.row){
            reset({
                id:'',
                person:{
                    name: '',
                    surname: '',
                    patronymic: '',
                    snils: '',
                    phone: '',
                    birthDate: '',
                    gender: '',
                    consent: ''
            }})
        } else {
            reset({
                id:props.row.id,
                person:props.row.person
            })
        }
    }, [reset, props]);

    useEffect(()=>{
        if (autoFocusItem.current) autoFocusItem.current.focus()
    },[])
    
    useEffect(()=>{
        // let isMounted = true
        const loadData = () =>{
            //setIsLoading(true)
            if (prev_snils !== params.snils) {
                setPrevSnils(params.snils)
                context.apiService.getPersons(params).then(data => {
                    // if (isMounted) {
                        if (data.content && data.content.length === 1 && params.snils){
                            //reset({person:{...data.content[0]}})
                            setValue("person", data.content[0])
                        }
                    // }
                    //setIsLoading(false)
                })
            }
        }
        loadData()
        // return ()=>{isMounted=false}
    },[context.apiService, params, setValue, person, prev_snils])

    const getFormErrorMessage = (name) => {
        if (!errors.person) return <></>
        return errors.person[name] && <small className="p-error">{errors.person[name].message}</small>
    };


    return (
        <div>
        <Toast ref={toast} position="top-center" />
        <form className={"mt-7"} onSubmit={handleSubmit(onSubmit)}>
            {!isOneLineEditorMode && <div style={{display:"grid", gridTemplateColumns:"1fr 1fr", marginTop:"0.5rem"}}>
                <span>
                    <Controller
                        name="person.snils"
                        control={control}
                        // rules={{ required: 'Поле обязательно для заполнения.'}}
                        render={({field, fieldState}) =>
                            <div style={firstColumnGrid} className="mr-2">
                                <label>СНИЛС</label>
                                <InputMask
                                    value={field.value}
                                    className={classNames({ 'p-invalid': fieldState.invalid }) }
                                    {...field}  mask="999-999-999 99" autoComplete="off"
                                    onComplete={(data)=>{setParams({...params, snils:data.value})}}
                                    onChange={(e)=>{field.onChange(e)}}
                                />
                            </div>}
                    />
                    {getFormErrorMessage('snils')}
                </span>
                <span>
                    <Controller
                        name="person.phone"
                        control={control}
                        rules={{ required: 'Поле обязательно для заполнения.'}}
                        render={({field, fieldState}) =>
                            <div style={secondColumnGrid}>
                                <label>Телефон</label>
                                <InputMask
                                    className={classNames({ 'p-invalid': fieldState.invalid }) }
                                    {...field} mask="+7(999) 999-99-99" autoComplete="off"
                                />
                            </div>}
                    />
                    {getFormErrorMessage('phone')}
                </span>
            </div> }

            { isOneLineEditorMode && <div>
                <Controller
                    name="person.snils"
                    control={control}
                    rules={{ required: 'Поле обязательно для заполнения.'}}
                    render={({field, fieldState}) =>
                        <div style={grid}>
                            <label>СНИЛС</label>
                            <InputMask
                                className={classNames({ 'p-invalid': fieldState.invalid }) }
                                {...field} mask="999-999-999 99" autoComplete="off"
                            />
                        </div>}
                />
                {getFormErrorMessage('snils')}
                <Controller
                    name="person.phone"
                    control={control}
                    rules={{ required: 'Поле обязательно для заполнения.'}}
                    render={({field,fieldState}) =>
                        <div style={grid}>
                            <label>Телефон</label>
                            <InputMask
                                className={classNames({ 'p-invalid': fieldState.invalid }) }
                                {...field} mask="+7(999) 999-99-99" autoComplete="off"
                            />
                        </div>}
                />
                {getFormErrorMessage('phone')}
            </div>}

            <Controller
                name="person.surname"
                control={control}
                rules={{ required: 'Поле обязательно для заполнения.'}}
                render={({field, fieldState}) =>
                <div style={grid}>
                    <label>Фамилия</label>
                    <InputText
                        className={classNames({ 'p-invalid': fieldState.invalid }) }
                        {...field} autoComplete="off"
                    />
                </div>}
            />
            {getFormErrorMessage('surname')}
            <Controller
                name="person.name"
                control={control}
                rules={{ required: 'Поле обязательно для заполнения.'}}
                render={({field, fieldState}) =>
                <div style={grid}>
                    <label>Имя</label>
                    <InputText
                        className={classNames({ 'p-invalid': fieldState.invalid }) }
                        {...field} autoComplete="off"
                    />
                </div>}
            />
            {getFormErrorMessage('name')}
            <Controller
                name="person.patronymic"
                control={control}
                rules={{ required: 'Поле обязательно для заполнения.'}}
                render={({field, fieldState}) =>
                <div style={grid}>
                    <label>Отчество</label>
                    <InputText
                        className={classNames({ 'p-invalid': fieldState.invalid }) }
                        {...field} autoComplete="off"
                    />
                </div>}
            />
            {getFormErrorMessage('patronymic')}

            <div style={{display:"grid", gridTemplateColumns:"1fr 1fr", marginTop:"0.5rem", alignItems: "center"}}>
                <span>
                    <Controller
                        name = "person.birthDate"
                        control={control}
                        rules={{ required: 'Поле обязательно для заполнения.'}}
                        render = {({field, fieldState}) =>
                            <div className="mr-2" style={firstColumnGrid}>
                                <label>Дата рождения</label>
                                <DateEditor
                                    overlayPos="top"
                                    style={{width:"100%"}}
                                    className={classNames({ 'p-invalid': fieldState.invalid }) }
                                    value={field.value && parseISO(field.value)}
                                    onChange={(e)=>{
                                        field.onChange(e?.toISOString())
                                    }}
                                />
                            </div>
                        }
                    />
                    {getFormErrorMessage('birthDate')}
                </span>
                <span>
                <Controller
                    name = "person.gender"
                    control={control}
                    rules={{ required: 'Поле обязательно для заполнения.'}}
                    render = {({field,fieldState}) =>
                        <div style={secondColumnGrid}>
                            <label>Пол</label>
                            <Dropdown className={classNames({ 'p-invalid': fieldState.invalid }) }  {...field} options={genders} />
                        </div>
                    }

                />
                {getFormErrorMessage('gender')}
                </span>
            </div>

            <Controller
                name="person.consent"
                control={control}
                render={({field,fieldState})=>
                    <div className="mt-4">
                        <label>Дано разрешение на обработку персональных данных</label>
                        <span>
                            <Checkbox className="ml-2" checked={field.value} onChange={field.onChange}/>
                        </span>
                    </div>
                }
            />
            <div>{consent}</div>
            <div className="mt-4" style={{textAlign:"center"}}>
                <Button disabled={!consent} label="Сохранить" className="mr-2 p-button-success" type="submit" />
                <Button label="Отмена"  onClick={()=>{props.onFinished()}} />
            </div>

        </form>
        </div>
    );
}

PersonEditor.propTypes = {
    onFinished: PropTypes.func.isRequired,
    row: PropTypes.object
}

export default PersonEditor;
