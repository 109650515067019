import React, {useContext, useEffect, useState} from 'react';
import SplitPane from "react-split-pane";
import "../Resizer.css"
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {AppContext} from "../AppContext";
import {Column} from "primereact/column";
import Org from "./Org";
import {BlockUI} from "../Common/BlockUI";
import {Paginator} from "primereact/paginator";
import {Dialog} from "primereact/dialog";
import {ClearButtonOutlined} from "../Common/Styles";


function Pharmacies(props) {
    const defaultValues = {
        id:undefined,
        organization: {
            id: undefined,
            name: "",
            address: "",
            phone: ""
        },
    }

    const [selectedOrg, setSelectedOrg] = useState(undefined);
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })

    const context = useContext(AppContext);

    const onSavePharmacy = (data) =>{
        context.apiService.savePharmacy(data).then(data=>{
            if (items.find(i=>i.id === data.id))
                setItems(items.map(i=>{if (i.id === data.id) return data; else return i}))
            else {
                items.push(data)
                setItems([...items])
            }
            setSelectedOrg(data)
        })
    }

    useEffect(()=>{
        let isMounted = true
        const loadData = () =>{
            setIsLoading(true)
            context.apiService.getPharmacies(lazyParams).then(data =>{
                if (isMounted){
                    setItems(data.content === undefined ? [] : data.content)
                    setTotal(data.totalElements)
                    setIsLoading(false)
                }
            })
        }
        loadData()
        return ()=>{isMounted=false}
    },[context.apiService, lazyParams])

    const onChangePage = (e)=>{
        setLazyParams({...lazyParams, first:e.first, rows:e.rows, page: e.page })
    }

    const deleteTemplate = (rowData, options) => {
        return <ClearButtonOutlined
            style={{width:"20px",height:"20px"}}
            type="button"
            icon='pi pi-trash'
            severity="danger"
            className="p-button-sm p-button-text"
            onClick={()=>{
                setSelectedOrg(rowData);
                setShowDeleteDialog(true)
            }}
        />;
    };

    const deleteDialogFooterContent = (
        <div>
            <Button label="Да" icon="pi pi-check"  severity="danger" onClick={() =>{
                context.apiService.deletePharmacy(selectedOrg).then(()=>{
                    setItems(items.filter(i=>i.id !== selectedOrg.id))
                    setSelectedOrg(undefined)
                })
                setShowDeleteDialog(false)
            }} />
            <Button label="Нет" icon="pi pi-check" onClick={() => setShowDeleteDialog(false)} autoFocus />
        </div>
    );

    return (
        <div>

            {showDeleteDialog && <Dialog
                header="ВНИМАНИЕ"
                visible={showDeleteDialog}
                onHide={()=>{setShowDeleteDialog(false)}}
                footer={deleteDialogFooterContent}
            >
                <div>
                    Удалить аптеку:
                </div>
                <div className={"mt-2"}>
                    <strong>{selectedOrg?.organization?.name}?</strong>
                </div>
            </Dialog>}

            <SplitPane
                split="vertical"
                primary="first"
                defaultSize="30%"
                style={{position:"relative"}}
            >
                <div className="ml-2">
                    <BlockUI blocking={isLoading}>
                        <DataTable
                            value={items}
                            selection={selectedOrg}
                            selectionMode="single"
                            onSelectionChange={e => {
                                if (e.value) setSelectedOrg(e.value);
                            }}
                        >
                            <Column header="Наименование" field="organization.name" />
                            <Column style={{width:"30px" }} body={deleteTemplate}></Column>
                        </DataTable>
                        <Paginator
                            rows={lazyParams.rows} first={lazyParams.first} totalRecords={total}
                            onPageChange={onChangePage}
                            leftContent={()=>(
                                <Button
                                    icon="pi pi-plus"
                                    rounded onClick={()=>{
                                    setSelectedOrg({...defaultValues})
                                }}/>
                            )}
                        />
                    </BlockUI>
                </div>
                <div>
                    <Org org={selectedOrg} onSaveOrg={onSavePharmacy}/>
                </div>
            </SplitPane>
        </div>
    );
}

export default Pharmacies;
