import React, {useContext, useEffect, useRef, useState} from 'react';
import Rp from "./Rp";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import SplitPane from "react-split-pane";
import "../Resizer.css"
import {Button} from "primereact/button";
import {AppContext} from "../AppContext";
import {Panel} from "primereact/panel";
import SearchInput from "../Common/SearchInput";
import {Dialog} from "primereact/dialog";
import {RadioButton} from "primereact/radiobutton";
import 'react-block-ui/style.css';
import {Toast} from "primereact/toast";
import {BlockUI} from "../Common/BlockUI";
import {ClearButtonOutlined} from "../Common/Styles";

function RpList(props) {

    const defaultValues = {
        name: "",
        mds: "",
        action: "",
        items:[]
    }

    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [rp, setRp] = useState(undefined)
    const [total, setTotal] = useState(0);
    const [isDirty, setIsDirty] = useState(false)
    const [showWarningDialog, setShowWarningDialog] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [nextRp, setNextRp] = useState(undefined)
    const [isSaving, setIsSaving] = useState(false);
    const toast = useRef(null);

    const context = useContext(AppContext)

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        name: "",
        sort: "rp_name"
    })
    const [selectedRp, setSelectedRp] = useState(undefined);

    useEffect(() => {

        if (!selectedRp){
            setRp(undefined)
            return
        }

        if (!selectedRp.id) {
            setRp( selectedRp )
            return
        }

        let isMounted = true
        const loadData = () =>{
            setIsDataLoading(true)
            context.apiService.getRpByID(selectedRp.id).then(data =>{
                if (isMounted){
                    if (data){
                        setRp(data)
                    } else {
                        setRp(undefined)
                    }
                    setIsDataLoading(false)
                }
            })
        }
        loadData()
    }, [selectedRp, context.apiService]);

    useEffect(()=>{
        let isMounted = true
        const loadData = () =>{
            setIsLoading(true)
            context.apiService.getRpNames(lazyParams).then(data =>{
                if (isMounted){
                    if (data){
                        setItems(data.content === undefined ? [] : data.content)
                        setTotal(data.totalElements)
                    } else {
                        setItems(undefined)
                        setTotal(0)
                    }
                    setIsLoading(false)
                }
            })
        }
        loadData()
        return () =>{ isMounted = false }

    },[context.apiService, lazyParams])

    const createRp = () =>{
        setSelectedRp({...defaultValues})
    }

    const saveRp = (e) =>{
        setIsSaving(true)
        context.apiService.saveRp(e).then(data=>{
            if (items.find(i=>i.id === data.id))
                setItems(items.map(i=>{if (i.id === data.id) return data; else return i}))
            else {
                items.push(data)
                setItems([...items])
            }
            setSelectedRp(data)
            setIsSaving(false)
        }).catch(data =>{
            setIsSaving(false)
            showError(data)
        })
    }

    const onChangePage = (e)=>{
        setLazyParams({...lazyParams, first:e.first, rows:e.rows, page: e.page })
    }

    const warningDialogFooter = (
        <React.Fragment>
            <Button label="Нет" autoFocus={true} icon="pi pi-times" className="p-button-success"
                    onClick={() => {setShowWarningDialog(false)}}
            />
            <Button label="Да" icon="pi pi-check" className="p-button-danger"
                    onClick={()=>{setSelectedRp(nextRp); setShowWarningDialog(false)}}
            />
        </React.Fragment>
    );
    const deleteDialogFooterContent = (
        <div>
            <Button label="Да" icon="pi pi-check"  severity="danger" onClick={() =>{
                context.apiService.deleteRp(selectedRp).then(()=>{
                    setItems(items.filter(i=>i.id !== selectedRp.id))
                    setSelectedRp(undefined)
                })
                setShowDeleteDialog(false)
            }} />
            <Button label="Нет" icon="pi pi-check" onClick={() => setShowDeleteDialog(false)} autoFocus />
        </div>
    );
    const showError = (data) => {
        toast.current.show({severity:'error', summary: 'Внимание', detail:`Произошла ошибка во время сохранения записи! ${data}`, life: 3000});
    }

    const deleteTemplate = (rowData, options) => {
        return <ClearButtonOutlined
            style={{width:"20px",height:"20px"}}
            type="button"
            icon='pi pi-trash'
            severity="danger"
            className="p-button-sm p-button-text"
            onClick={()=>{
                setSelectedRp(rowData);
                setShowDeleteDialog(true)
            }}
        />;
    };

    return (
        <BlockUI blocking={isSaving}>

        <div>
            <Toast ref={toast} position="top-center" />
            <Dialog
                visible={showWarningDialog}
                onHide={()=>{setShowWarningDialog(false)}}
                header="Внимание" modal
                footer={warningDialogFooter}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    <span>Сделанные изменения будут утеряны! Продолжить переход на другую пропись ? </span>
                </div>
            </Dialog>

            {showDeleteDialog && <Dialog
                header="ВНИМАНИЕ"
                visible={showDeleteDialog}
                onHide={()=>{setShowDeleteDialog(false)}}
                footer={deleteDialogFooterContent}
            >
                <div>
                    Удалить компонент:
                </div>
                <div>
                    <strong>{selectedRp?.name}?</strong>
                </div>
            </Dialog>}

            <SplitPane primary="first" defaultSize={500} style={{position:"relative"}}>
                <div>
                    <div>
                        <Panel>
                            <SearchInput
                                value={lazyParams.name}
                                style={{width:"100%"}}
                                onChange={(e)=>{
                                    setLazyParams({...lazyParams, name:e.target.value, page:0})
                                }}
                            />
                            <div className="mt-1 mb-1" style={{display:"flex"}}>
                                <div className="ml-2">Сортировать по: </div>
                                <div className="ml-2">
                                    <RadioButton
                                        inputId="sort1" name="sort" value="rp_name"
                                        onChange={(e) => setLazyParams({...lazyParams, sort:e.value})}
                                        checked={lazyParams.sort === 'rp_name'}
                                    />
                                    <label className="ml-2" htmlFor="sort1">названию</label>
                                </div>
                                <div className="ml-2">
                                    <RadioButton
                                        inputId="sort2" name="sort" value="id"
                                        onChange={(e) => setLazyParams({...lazyParams, sort:e.value})}
                                        checked={lazyParams.sort === "id"}
                                    />
                                    <label className="ml-2" htmlFor="sort1">порядку добавления</label>
                                </div>
                            </div>
                        </Panel>
                        <BlockUI blocking={isLoading} >
                        <DataTable
                            className="m-2"
                            value={items}
                            emptyMessage="Нет записей"
                            lazy
                            selectionMode="single"
                            selection={selectedRp}
                            paginator
                            first={lazyParams.first}
                            rows={lazyParams.rows}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            totalRecords={total}
                            onSelectionChange={e => {
                                if (e.value){
                                    if (!isDirty)
                                        setSelectedRp(e.value);
                                    else {
                                        setNextRp(e.value)
                                        setShowWarningDialog(true)
                                    }
                                }
                            }}
                            onPage={onChangePage}
                            paginatorLeft={()=>(
                                <Button
                                    className="ml-2"
                                    icon={"pi pi-plus"}
                                    rounded
                                    onClick={createRp}
                                />
                            )}
                        >
                            <Column field="name" header="Наименование"/>
                            <Column style={{width:"30px" }} body={deleteTemplate}></Column>
                        </DataTable>
                        </BlockUI>
                    </div>

                </div>
                <div>
                    <BlockUI blocking={isDataLoading}>
                        <Rp rp={rp} onSaveRp={saveRp} setIsDirty={setIsDirty} readOnly={false} showRecipe/>
                    </BlockUI>
                </div>


            </SplitPane>
        </div>
        </BlockUI>
    );
}

export default RpList;
