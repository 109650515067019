import React, {useRef} from 'react'
import {Dialog} from "primereact/dialog";
import PrescriptionEditor from "../../../Common/PrescriptionEditor";
import {Stepper} from "primereact/stepper";
import {StepperPanel} from "primereact/stepperpanel";
import MonitoringEditor from "../../../Common/MonitoringEditor";
import {usePrescriptionsStore} from "../../../../store/states/PrescriptionsStore";

function PrescriptionDialog(props) {
    const stepperRef = useRef(null);
    const prescriptionStore = usePrescriptionsStore()
    return (
        <Dialog header={"Назначение"} onHide={props.onHide} visible={props.visible}
            style={{ width: '90%' }} breakpoints={{ '1200px': '100%' }}
        >
            { prescriptionStore.editable.sentToPharmacy && <Stepper ref={stepperRef}>
                <StepperPanel header="Пропись">
                    <PrescriptionEditor onSave={props.onSave}/>
                </StepperPanel>
                <StepperPanel header="Наблюдение" >
                    <MonitoringEditor onSave={props.onSave}/>
                </StepperPanel>
            </Stepper> }
            { !prescriptionStore.editable.sentToPharmacy &&
                <PrescriptionEditor onSave={props.onSave}/>
            }
        </Dialog>
    )
}

export default PrescriptionDialog
