import React, {useContext} from 'react'
import UniversalList from "../Common/UniversalList";
import {AppContext} from "../AppContext";
import Mkb10Filter from "./Mkb10Filter";

function Mkb10List(props) {

    const context = useContext(AppContext)
    const itemsService = context.apiService

    return (
        <div>
            <UniversalList
                header="МКБ10"
                visible={props.visible}
                filtersTemplate={Mkb10Filter}
                //rowViewerTemplate={PatientViewer}
                //rowEditorTemplate={PersonEditor}
                //fullPageEditor={true}
                onHide={props.onHide}
                onGetData={itemsService.getMkb10.bind(itemsService)}
                onSelectItem={props.onSelectItem}
                //onEditRow={(row)=>{console.log(row)}}
                //onDeleteRow={(row)=>{console.log(row)}}
                //onAddRow={(row)=>{console.log(row)}}
                columns = {[
                    {id:1, field:"code", header:"Код"},
                    {id:2, field:"description", header:"Описание"},
                ]}
            />

        </div>
    );
}

export default Mkb10List
